.fleetUsage {
    font-family: ABBVoice;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 12px;
    gap: 12px;
}
.float-left-var{
    --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1* var(--bs-gutter-y)); 
        margin-left: 0px !important;
        margin-right: auto !important;
        width: 40% !important;
        margin-bottom: 8px;
}