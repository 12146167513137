.tabfleetview {
  //border: 1px solid #e5e5e5;

  .tabicon {
    padding: 0px 12px 0px 0px;
    //width: 32px;
    //height: 32px;
    padding-right: 7px;
  }

  .tabtitle {
    color: #5a5a5a;
    font-size: 16px;
    font-family: "mediumFont";
    padding-right: 16px;
    padding-left: 16px;
  }
}
.tabfleetview .nav-tabs {
  border: 0px;
  .nav-link {
    padding: 1px 0px;
    
    text-align: center;
    max-width: 200px;
    border-color: transparent;
    border-width: 4px 0px 0px 0px;

    .tabtitle {
      color: #5a5a5a;
      border-left: 1px solid #bababa;
      display: block;
      margin-top: 5px;
    }
    &:hover {
      border-color: #abd3fd;
      border-width: 4px 0px 0px 0px;
      border-radius: unset;
    }
  }
  .nav-link.active {
    border-color: #2e92fa;
    border-width: 4px 0px 0px 0px;
    border-radius: unset;
    background: none;
    .tabtitle {
      //color: #2e92fa;
    }
    .tabIcon use,
    .tabIcon g {
      fill: #2e92fa;
    }
  }
}
.rw-widget-container {
  border-radius: 0;
}

.tabfleetviewmap .nav-tabs {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 7px 7px 7px 17px;
  .nav-link {
    border-color: transparent;
    border-width: 4px 0px 0px 0px;
    border-radius: unset;
    padding: 7px 15px;
    width: auto;
    max-width: 30%;
    text-align: center;
    &:hover {
      border-color: #abd3fd;
      border-width: 4px 0px 0px 0px;
      border-radius: unset;
    }
    .tabtitle {
      color: #5a5a5a;
      font-size: 14px;
      font-family: "mediumFont";
      line-height: 18px;
      text-align: center;
    }
    .tabIcon use,
    .tabIcon g {
      fill: #2e92fa;
    }
  }
  .nav-link.active {
    border-color: #2e92fa;
    border-width: 4px 0px 0px 0px;
    border-radius: unset;

    .tabtitle {
      //color: #2e92fa;
      color: #464646;
      font-size: 14px;
      font-family: "mediumFont";
      line-height: 18px;
      text-align: center;
    }
    .tabIcon use,
    .tabIcon g {
      fill: #2e92fa;
    }
  }
}

.tabmapcontainer {
  margin: 7px 7px 7px 17px;
}

.sliderlist {
  padding: 0px;
  cursor: pointer;
  border-bottom: 0px solid #ffff;
  background-color: #ffffff;
  //font-weight: 600;
  width: 100%;
  text-align: left;
  border-left: 6px solid transparent;
  // &:hover {
  //   border-left: 6px solid #0c74da;
  //   box-sizing: border-box;
  //   background-color: #ddedfd;
  // }
  .text {
    text-align: left !important;
  }
}

.sliderlistactive {
  border-left: 6px solid #0c74da;
  box-sizing: border-box;
  background-color: #ddedfd;
}

.sliderlistcontainer {
  margin-top: 20px;
  overflow: auto;
  width: 100%;
  max-height: 310px;
  min-height: 100px;
  border: 1px solid #e5e5e5;
}
.matrixIcon {
  background-color: transparent;
  cursor: pointer;
}

.tabfleetview .btn-link-custom {
  color: #0c74da;
}
.tabfleetview button:disabled {
  //color: #464646;
  opacity: 0.4;
}

#fleet-tab-Overview .tabtitle,
#fleet-tab-Overview-vehicle-view .tabtitle {
  border-left: 0px solid #ececec;
}
.overview-grey-info {
  background-color: #fafafa;
  min-height: 105px;
  text-align: center;
  padding: 0px;
  margin-right: 20px;
  max-width: 31.8%;
  flex: 0 0 31.8%;
  font-family: "mediumFont";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .overview-value {
    font-size: 20px;
  }
}
#last-tab {
  margin-right: 0px;
}
#fleet-tabpane-Overview .sub {
  font-family: "mediumFont";
  padding-bottom: 20px;
}
.dateupdated {
  margin-bottom: 20px;
}

#fleet-tabpane-Energy-throughput {
  .nav-tabs {
    max-width: 205px;
    margin: 20px auto;
  }
  .nav-link {
    width: 50%;
    min-height: 30px;
    border: 1px solid #c8c8c8;
    border-radius: 0;
    background-color: #dcdcdc;
    &:hover {
      background-color: #fafafa;
    }
  }

  .nav-link.active {
    background-color: #ddedfd;
    border: 1px solid #2e92fa;
    .tabtitle {
      color: #004c97;
    }
  }
  .tabtitle {
    font-size: 16px;
    padding: 0;
    border: 0;
    color: #464646;
  }
}

.main-tab-content {
  margin-bottom: 16px;
}
#fleet-tabpane-Overview {
  padding: 10px 20px;
}
#fleet-tabpane-Energy-throughput,
#fleet-tabpane-SoC-range-usage {
  padding: 0px 2%;
}

// #fleet-fleetstatistics{
//   margin-top: 25px;
// }
.fleetStatTitle {
  line-height: 30px;
  font-family: mediumFont;
  font-size: 14px;
  text-align: left;
  padding-left: 3%;
  font-weight: 400;
}
.outer-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.fleetStat-select{
  border-radius: 0px;
  font-weight: bold;
  font-size: 14px;
  option {
    font-weight: bold;
    font-size: 14px;
  }
}
.nodatafound div:first-child {
  background-image: url("../../assets/images/abb_nodata_information.svg");
  background-repeat: no-repeat;
  padding-left: 10px;
  background-position: left;
  width: 87px;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: -16px;
}

// .heatmap-wrapper::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	border-radius: 10px;
// 	background-color: #F5F5F5;
// }

// .heatmap-wrapper::-webkit-scrollbar
// {
// 	width: 5px;
// 	background-color: #F5F5F5;
// }

// .heatmap-wrapper::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
// 	background-color: #e8e8e8;
// }
#fleet-tabpane-fleet-eventlist{
  //position: absolute;
  min-height: 500px;
}

.fixed {
  position: fixed;
  z-index: 900;
  width: 100%;
  top: 105px;
  background-color: #fff;
  padding: 10px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  //transition: all .2s ease-in-out;
  transform-origin: right top;
}