.event-list-filter {
  .eventfiltercont {
    width: 97%;
    //height: 10px;
    background-color: #f5f5f5;
    .eventfiltertitlebar {
      background-color: #f5f5f5;
      line-height: 40px;
      border-bottom: 1px solid;
      border-bottom-color: #bababa;
      .text {
        // color: #004c97;
        font-family: ABBvoice;
        font-size: 14px;
        font-weight: 600;
        vertical-align: middle;
      }
      .iconapply {
        float: right;
        margin-right: 0px;
        width: 40px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background-color: #dcdcdc;
        }
      }
      .iconcollapse {
        float: right;
        margin-right: -15px;
        width: 40px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background-color: #dcdcdc;
        }
      }
    }
    .filtercont {
      //max-width: 450px;
      margin: 8px 0px;
      .texttime {
        // color: #004c97;
        font-family: ABBvoice;
        font-size: 14px;
        //font-weight: 600;
        vertical-align: middle;
      }
      .efilterdatePicker {
        max-width: 14%;
        margin-right: 10px;
        margin-bottom: 8px;
        // display: inline-block;
        .text {
          // color: #004c97;
          font-family: ABBvoice;
          font-size: 14px;
          //font-weight: 600;
          vertical-align: middle;
        }
      }
      .efiltertext {
        //align-self: center;
        margin-top: 6px;
        margin-right: 10px;
        .textdays {
          color: #1f1f1f4d;
          font-family: ABBvoice;
          font-size: 14px;
          //font-weight: 600;
          vertical-align: middle;
        }
      }
      .efilterdatetype {
        //max-width: 50%;
        width: 50%;
        margin-bottom: 8px;
        .toggle-button {
          max-width: 110px;
          height: 32px;
          font-family: ABBvoice;
          font-size: 14px;
          box-sizing: border-box;
          //width: 100%;
          border: 1px solid #cccccc;
          background-color: #e8e8e8;
          border-radius: 0px;
          color: #000;
          line-height: 17px;
          text-align: left;
          padding: 7px;
          //margin-top: 26px;
          cursor: pointer;
          &:hover {
            background-color: #fcfcfc;
            color: #464646;
          }
        }
        .activeLightBlue {
          background-color: #ddedfd;
          border: 1px solid #2e92fa;
          pointer-events: none;
          color: #004c97;
        }

        // max-width: 50%;
        // width: 50%;
        // margin-bottom: 8px;
        // // display: inline-block;
        // .toggle-button {
        //   // display: inline-block;
        //   max-width: 101px;
        //   height: 30px;
        //   font-family: mediumFont;
        //   font-size: 14px;
        //   box-sizing: border-box;
        //   width: 100%;
        //   border: 1px solid #cccccc;
        //   background-color: #e8e8e8;
        //   border-radius: 0px;
        //   color: #000;
        //   line-height: 17px;
        //   text-align: center;
        //   padding: 0px 0px;
        //   cursor: pointer;
        //   &:hover {
        //     background-color: #fcfcfc;
        //     color: #464646;
        //   }
        // }
        // .disabled {
        //   pointer-events: none;
        //   opacity: 0.5;
        //   button {
        //     color: #464646;
        //   }
        // }
      }
      .eventtype {
        width: 100%;
        .text {
          // color: #004c97;
          font-family: ABBvoice;
          font-size: 14px;
          //font-weight: 600;
          vertical-align: middle;
        }
        .toggle-button {
          max-width: 35px;
          height: 35px;
          font-family: ABBvoice;
          font-size: 14px;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid #cccccc;
          background-color: #fff;
          border-radius: 0px;
          color: #000;
          line-height: 17px;
          text-align: center;
          padding: 0px 0px;
          cursor: pointer;
          border-width: 1px 1px 1px 1px;
        }
        .activeEventBtn {
          background-color: #ddedfd;
          border: 1px solid #2e92fa;
          //pointer-events: none;
          color: #004c97;
        }
      }
      .vehiclenumber {
        .text {
          // color: #004c97;
          font-family: ABBvoice;
          font-size: 14px;
          //font-weight: 600;
          vertical-align: middle;
        }
      }
      .esssn {
        .text {
          // color: #004c97;
          font-family: ABBvoice;
          font-size: 14px;
          //font-weight: 600;
          vertical-align: middle;
        }
      }
      .keywordsearch {
        .text {
          // color: #004c97;
          font-family: ABBvoice;
          font-size: 14px;
          //font-weight: 600;
          vertical-align: middle;
        }
        .keywordsearchdropdown {
          width: 140px;
        }
        .searchkeyword {
          width: 135px;
          min-height: 34px;
          border: 1px solid#d3d3d3;
          padding-left: 5px;
        }
      }
    }
  }
  #event-list {
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
    tbody > tr,
    thead > tr {
      display: table;
      width: 100%;
    }
    tbody,
    thead {
      display: block;
      max-height: 60vh;
      overflow: auto;
    }
    th {
      border-top: 0px;
      border-bottom: 1px solid #afafaf;
      font-weight: 500;
    }
    th,
    td {
      padding: 8px;
      display: inline-block;
    }
    .col1 {
      width: 10%;
    }
    .col2 {
      width: 15%;
    }
    .col3 {
      width: 15%;
    }
    .col4 {
      width: 15%;
    }
    .col5 {
      width: 15%;
    }
    .col6 {
      width: 30%;
    }
  }
  .fil-con {
    max-width: 450px;
    margin: 8px 0px;
    .inline-display {
      display: inline-block;
    }
    .inline-display:first-child {
      max-width: 145px;
      width: 145px;
    }
    .inline-display:nth-child(2) {
      width: calc(100% - 145px);
    }
    .toggle-button {
      max-width: 35px;
      height: 35px;
      font-family: ABBvoice;
      font-size: 14px;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #cccccc;
      background-color: #fff;
      border-radius: 0px;
      color: #000;
      line-height: 17px;
      text-align: center;
      padding: 0px 0px;
      cursor: pointer;
      border-width: 1px 0 1px 1px;
    }
    .toggle-button:last-child {
      border-width: 1px 1px 1px 1px;
    }
  }
  .rbt {
    width: calc(100% - 35px);
    display: inline-block;
    .rbt-aux {
      align-items: center;
      display: flex;
      bottom: 0;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 34px;
    }
    .rbt-close {
      z-index: 1;
    }
    .dropdown-menu {
      border-radius: 0;
    }
    .dropdown-item {
      font-size: 12px;
      font-family: ABBvoice;
    }
    .rbt-input-main {
      height: 37px;
      border-bottom: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding: 5px;
      background: transparent;
      font-family: ABBvoice;
      font-size: 14px;
    }
    .form-control:focus {
      box-shadow: none;
    }
  }
  .table {
    th {
      border-top: 0px;
      border-bottom: 1px solid #afafaf;
      font-family: ABBvoice;
      font-weight: normal;
    }
    td {
      font-family: ABBvoice;
      white-space: pre-line;
      padding: 8px 4px;
    }
    th {
      cursor: default;
      pointer-events: none;
      padding: 8px 4px;
      min-width: 150px;
    }
    th:nth-child(3),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(8),
    th:nth-child(9),
    th:nth-child(10) {
      cursor: pointer;
      pointer-events: initial;
    }
    th:nth-child(1) {
      min-width: 40px;
    }
    th:nth-child(2) {
      min-width: 85px;
      text-align-last: left;
    }
    th:nth-child(3) {
      min-width: 160px;
    }
    td:nth-child(1),
    td:nth-child(2) {
      text-align-last: center;
      vertical-align: middle;
    }
    th:nth-child(11),
    td:nth-child(11),
    th:nth-child(12),
    td:nth-child(12),
    th:nth-child(13),
    td:nth-child(13),
    th:nth-child(14),
    td:nth-child(14),
    th:nth-child(15),
    td:nth-child(15),
    th:nth-child(16),
    td:nth-child(16),
    th:nth-child(17),
    td:nth-child(17),
    th:nth-child(18),
    td:nth-child(18),
    th:nth-child(19),
    td:nth-child(19),
    th:nth-child(20),
    td:nth-child(20){
      display: none;
    }
  }
  .pagination {
    float: right;
    margin: 8px 0px;
    .pagecount {
      line-height: 24px;
    }
  }
  .fil-con {
    display: none;
  }

  .btn-link {
    width: 16px;
    padding: 0;
    line-height: normal;
    vertical-align: top;
    text-decoration: none !important;
  }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: none !important;
  }
  .ellipsetxt {
    width: 67px;
    font-size: 12px;
  }
  .sortablecon:hover {
    .sortable {
      width: 16px;
      display: inline-block;
      height: 14px;
      vertical-align: middle;
      background-image: url("../../assets/images/arrow_up.svg");
      background-repeat: no-repeat;
      background-position: right;
      vertical-align: middle;
    }
  }
  .active-sort {
    border-bottom: 2px solid #0056b3 !important;
  }
  .undo-btn {
    width: 16px;
  }
  .stress-link {
    font-size: 12px;
    font-family: ABBvoice;
    width: auto;
  }
}

#fleet-evnt-list,
#vehicle-evnt-list,
#product-evnt-list {
  // border: 2px solid red;
  padding-right: 0px;
}

.typefiltertogglebutton {
  max-width: 22px;
  height: 22px;
  font-family: ABBvoice;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #fff;
  border-radius: 0px;
  color: #000;
  line-height: 10px;
  text-align: left;
  padding: 3px;
  cursor: pointer;
  border-width: 1px 1px 1px 1px;
}
.activetypefilter {
  background-color: #ddedfd;
  border: 1px solid #2e92fa;
  //pointer-events: none;
  color: #004c97;
}
.searchkeyword {
  width: 135px;
  min-height: 37px;
  border: 1px solid#d3d3d3;
  padding-left: 5px;
  font-size: 12px;
  font-family: ABBvoice;
}
.daterangepickerimg {
  width: 1px;
  position: absolute;
}
.rw-list {
  max-height: 150px !important;
  overflow-y: auto;
}
